const IS_DEV = import.meta.env.DEV;

enum DevEnv {
  STAGING = 'staging',
  MOBILE = 'mobile',
  LOCAL = 'local',
}
const DEV_ENV: DevEnv = DevEnv.STAGING; // <-- change here to switch between local and staging

const STAGING_API_MAP = {
  [DevEnv.STAGING]: 'https://staging-api.agavehealth.com',
  [DevEnv.MOBILE]: 'https://staging-mobile.agavehealth.com',
  [DevEnv.LOCAL]: 'http://localhost:4000',
};

export const API_URL = IS_DEV ? STAGING_API_MAP[DEV_ENV] : 'https://api.agavehealth.com';

export const STREAM_API_URL = IS_DEV
  ? 'https://api-stream-staging1.agavehealth.com'
  : 'https://cm2wumgej57sondfvqxcyxya5m0vbpmv.lambda-url.us-east-1.on.aws';

export const THERAPY_WEB_URL = 'https://therapy.agavehealth.com';

export const AUTH0_DOMAIN = IS_DEV ? 'auth.agavehealth.com' : 'login.agavehealth.com';
export const AUTH0_CLIENT_ID = IS_DEV
  ? 'hUiiWl5osKKsPZTujxNQrAE94PjZpnUU'
  : 'NtdqSgGsOzPVZSUj071s4Lf2nzMJSXXN';
export const AUTH0_AUDIENCE = IS_DEV
  ? 'https://gothrive.us.auth0.com/api/v2/'
  : 'https://agavehealth.us.auth0.com/api/v2/';

export const STREAM_IO_API_KEY = IS_DEV ? 'wj54zhx6ghb2' : 'p7u7beerwt5g';

export const AGAVE_STRIPE_PRICE_ID = IS_DEV
  ? 'price_1MRbv4FPjwMtmgpRTmqbSoiS'
  : 'price_1MJDOyFPjwMtmgpRdIBC7g68';

export const DEFAULT_TIMEZONE = 'America/New_York';

export const PAGE_SIZES = [10, 30, 50];

export const STORAGE_PREFIX = 'agave';

export const DEFAULT_FILTERS = {
  page: 1,
  limit: PAGE_SIZES[1],
};
