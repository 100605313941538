import React from 'react';
import { Badge, BadgeProps, MantineColor } from '@mantine/core';
import { SubscriptionStatus } from 'api/user';

const statusColors: Record<SubscriptionStatus, MantineColor> = {
  free: 'green',
  premium: 'blue',
  canceled: 'red',
  paused: 'yellow',
  trial: 'black',
};

interface SubscriptionBadgeProps extends BadgeProps {
  status?: SubscriptionStatus;
  plan?: string | null;
}
const SubscriptionBadge = ({ status, plan, ...props }: SubscriptionBadgeProps) => {
  return status ? (
    <Badge color={statusColors[status]} {...props}>
      {status}
      {plan && plan !== 'PREMIUM' ? ` - ${plan}` : ''}
    </Badge>
  ) : null;
};

export default SubscriptionBadge;
